import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const fetchPackageData = async (data) => {
    try {
        const response = await axios.get(`${BASE_URL}agri/product/search/allProductDetailsByQR`, {params: data});
        return response.data
    } catch (err) {
        throw new Error(err);
    }
};

export const addFeedbackComment = async (data) => {
    const headers = {
        "Content-Type": "multipart/form-data"
    }
    try {
        const response = await axios.post(`${BASE_URL}agri/farmerRating/external`, data, {headers: headers});
        return response.data
    } catch (err) {
        throw new Error(err);
    }
};