import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./starRating.css";
import { FaStar } from "react-icons/fa";
const Star = ({ selected = false, onClick = (f) => f }) => (
  <div className={selected ? "star selected" : "star"} onClick={onClick}>
    <FaStar />
  </div>
);

Star.propTypes = {
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

const StarRating = ({rating, setSelectedRating, isRatingError, setRatingError}) => {

  const [totalStars, setTotalStars] = useState(5);
  const [selectedStars, setSelectedStars] = useState(rating);
  const change = (starsSelected) => {
    setSelectedStars(starsSelected);
    setSelectedRating(starsSelected);
    if (starsSelected > 0) {
      setRatingError(false);
    }
  }

  useEffect(() => {
    setSelectedStars(rating)
  }, [rating])


    return (
      <div className="star-rating">
        {[...Array(totalStars)].map((n, i) => (
          <Star
            key={i}
            selected={i < selectedStars}
            onClick={() => change(i + 1)}
          />
        ))}
      </div>
    )
}
export default StarRating