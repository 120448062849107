import {useEffect} from "react";


const Home = () => {


    useEffect(() => {
        window.location.href = 'https://lassana.com'
    },[])

    return(
        <div></div>
    )
}
export default Home