import React, {useEffect, useState} from "react";
import "./cropDetails.css";
import FarmerImg from "../../assets/images/farmer.png";
import commonImg from "../../assets/images/common-img.png";
import capsicumImage from "../../assets/images/capsicum.png";
import bitterImage from "../../assets/images/bitter-gourd.png";
import chiliImage from "../../assets/images/chillie.png";
import tomatoImage from "../../assets/images/tomato.png";
import logo from "../../assets/images/logo.png";
import usaidlogo from "../../assets/images/usaid_logo.png";
import doodleImg1 from "../../assets/images/doodle1.png";
import doodleImg3 from "../../assets/images/doodle3.png";
import DisplayStarRating from "../../components/StarRating/displayStarRating";
import {useMutation} from "react-query";
import {fetchPackageData} from "../../service/api";
import LoadingScreen from "../../components/Loading/LoadingScreen";
import NoProductFound from "../../components/NoProductFound/noProductFound";
import AddReview from "../../components/AddReview";

export const CropDetails = () => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [cropImage, setCropImage] = useState(commonImg);
    const [qrData, setQRData] = useState(null);
    const [qrCodeText, setQRCodeText] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);

    const {mutate: getPackageData} = useMutation({
        mutationKey: ["packageData"],
        mutationFn: (data) => fetchPackageData(data),
        onSuccess: (res) => {
            if (res && res.status === 200 && res.object) {
                setQRData(res.object)
                setError(false)
                setIsImageLoaded(true);
                setLoading(false)
            }
        },
        onError: (error) => {
            setLoading(false)
            setError(true)
        }
    });


    useEffect(() => {
        if (window.location.href.indexOf("qr=") !== -1 && window.location.search) {
            const sendQrcode = window.location.search.replace("?qr=", "");
            setQRCodeText(sendQrcode);
            getPackageData({qrCode: sendQrcode})
        }
    }, []);

    return (
        <div className="background">
            <div className="col1_row1 tab-logo">
                <img src={logo} alt="" className="logoImg"/>
                <img src={usaidlogo} alt="" className="logoImg usaid"/>
            </div>

            {isLoading ? (
                <LoadingScreen/>
            ) : (
                isError ? (
                    <NoProductFound/>
                ) : (
                    <div className={`cropDetails ${isImageLoaded ? "slide-in" : ""}`}>
                        <div className="column_1">
                            <div className="col1_row1 default-logo">
                                <img src={logo} alt="" className="logoImg"/>
                                <img src={usaidlogo} alt="" className="logoImg usaid"/>
                            </div>

                            {qrData &&
                                <>
                                    <div className="col_1_div">
                                        <div className="farmer-img">
                                            <img src={FarmerImg} alt="" className="farmerImg"/>
                                            <div className="farmer-details">
                                                <p className="farmerName">
                                                    <b>{`${qrData.farmerFirstName} ${qrData.farmerLastName}`}</b>
                                                </p>
                                                <p className="role">Farmer</p>
                                            </div>
                                        </div>

                                        <div className="other-details">
                                            <div className="bio-feedback">
                                                {qrData.farmerBio && qrData.farmerBio.length > 2 &&
                                                    <>
                                                        <p><b>Bio</b></p>
                                                        <p className="bio">
                                                            <i>"{`${qrData.farmerBio}`}"</i>
                                                        </p>
                                                    </>
                                                }
                                                {qrData.farmerFeedback && qrData.farmerFeedback.length > 2 &&
                                                    <>
                                                        <br/><p><b>Lassana Feedback</b></p>
                                                        <p className="lassana-feedback">{`${qrData.farmerFeedback}`}</p>
                                                    </>
                                                }
                                            </div>

                                            <div className="outer-div-star-ratings">
                                                <div className="star-rating-div">
                                                    {qrData?.farmerInternalRating != null &&
                                                        <>
                                                            <span> Lassana Rating </span>
                                                            <DisplayStarRating rating={qrData?.farmerInternalRating}/>
                                                        </>
                                                    }
                                                </div>
                                                <div className="star-rating-div">
                                                    {qrData?.farmerExternalRating != null &&
                                                        <>
                                                            <span> Customer Rating </span>
                                                            <DisplayStarRating rating={qrData?.farmerExternalRating}/>
                                                        </>
                                                    }
                                                </div>
                                            </div>


                                        </div>
                                    </div>

                                    <div className="col_2_div">

                                        <div className="cropDetails-col">
                                            <div className="row heading-bar">
                                                <p className="txt1">Crop Details</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Crop Name:</p>
                                                <p className="txt2">{`${qrData.cropType} (${qrData.cropTypeSinhalaName})`}</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Weight:</p>
                                                <p className="txt2">{`${qrData.unitValue} ${qrData.unitName}`}</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Field Size:</p>
                                                <p className="txt2">{`${qrData.farmingWidth}m x ${qrData.farmingLength}m`}</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Planted Date:</p>
                                                <p className="txt2">{qrData.farmingStartDate}</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Harvested Date:</p>
                                                <p className="txt2">{qrData.itemCropDate}</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Delivered Date:</p>
                                                <p className="txt2">{qrData.itemBatchCreatedDate}</p>
                                            </div>
                                            <div className="row">
                                                <p className="txt1">Packed Date:</p>
                                                <p className="txt2">{qrData.productCreatedDate}</p>
                                            </div>

                                        </div>

                                        <div className="rating-col">
                                            <div className="row heading-bar">
                                                <p className="txt1">Write a review</p>
                                            </div>
                                            <AddReview productQR={qrCodeText}/>
                                        </div>


                                    </div>

                                </>
                            }
                            <img src={doodleImg1} alt="" className="doodle1"/>
                        </div>
                        {qrData && cropImage &&
                            <div className={`column_2 ${qrData.cropType}`}>
                                <p className="cropName">{`${qrData.cropType}`}</p>
                                <img
                                    src={cropImage}
                                    alt=""
                                    className={`cropImg ${isImageLoaded ? "slide-in" : ""}`}
                                />
                                <img src={doodleImg3} alt="" className="doodle3"/>
                            </div>
                        }
                    </div>
                )
            )}
        </div>
    );
}
export default CropDetails;
