import React from "react";
import "./loadingScreen.css";

export default function LoadingScreen() {
  return (
    <div>
        <center>
      <div class="loader">
        <div class="load-inner load-one"></div>
        <div class="load-inner load-two"></div>
        <div class="load-inner load-three"></div>
        <span class="loader-text">Loading</span>
      </div>
      </center>
    </div>
  );
}
