import React from "react";
import { FaStar } from "react-icons/fa";

const DisplayStarRating = ({ rating }) => {
  const totalStars = 5;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < totalStars; i++) {
      stars.push(
        <span key={i} className={i < rating ? "filled-star" : "empty-star"}>
          <FaStar />
        </span>
      );
    }
    return stars;
  };

  return <div className="star-ratings">{renderStars()}</div>;
};

export default DisplayStarRating;
