import StarRating from "../StarRating/starRating";
import React, {useState} from "react";
import {Controller, Form, useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {addFeedbackComment} from "../../service/api";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";

const AddReview = ({productQR}) => {

    const [rating, setRating] = useState(0);
    const [isRatingError, setRatingError] = useState(false)
    const [hideSubmit, setHideSubmit] = useState(false)

    const {
        handleSubmit,
        control,
        reset,
        formState: {errors},
    } = useForm({
        mode: "onChange",
        defaultValues: {
            comment: ''
        },
    });

    const {mutate: addComment} = useMutation({
        mutationKey: ["commentData"],
        mutationFn: (data) => addFeedbackComment(data),
        onSuccess: (res) => {
            if (res.status === 200) {
                toast.success("Your Feedback added to the Farmer");
                setRating(0);
                reset();
                setHideSubmit(true)

            } else {
                toast.error("Feedback not added. Please check again shortly");
            }
        }
    });

    const onSubmit = data => {
        if (rating > 0) {
            if (data && data.comment && data.comment.length > 1) {
                const senData = new FormData();
                senData.set('rating', rating);
                senData.set('comment', data.comment);
                senData.set('productQR', productQR);
                senData.set('customerName', "NA");

                addComment(senData);
            }
        } else {
            setRatingError(true);
        }
    }

    return (
        <div className="row addReview">
            {/* <h1>Write a review</h1> */}
            <div className="reviewStar">
                <StarRating rating={rating} setSelectedRating={setRating} isRatingError={isRatingError} setRatingError={setRatingError}/>
                {isRatingError &&
                    <label className="error-label">Please add a rating for the product</label>
                }
            </div>
            <form className="review-form" onSubmit={handleSubmit(onSubmit)}>
                <label>Comments if any</label>
                <Controller
                    name="comment"
                    control={control}
                    rules={{
                        required: "Please enter valid feedback comment",
                        min: 2
                    }}
                    render={({field}) => (
                        <textarea
                            name="comment"
                            placeholder="Write your comment here..."
                            rows={6}
                            {...field}/>
                    )}
                />
                <label className="error-label">
                    {errors?.comment && "*" + errors?.comment?.message}
                </label>
                {!hideSubmit &&
                    <button type={"submit"}>Submit</button>
                }
            </form>
        </div>
    )
}
export default AddReview