import logo from './logo.svg';
import './App.css';
import CropDetails from './views/CropDetails/cropDetails';
import {Route, Routes} from "react-router-dom";
import Home from "./views/Home";
import {ToastContainer} from "react-toastify";

function App() {
  return (
    <div className="App">
      <Routes>
          <Route
              path="/"
              element={<Home/>}
          />
          <Route
              path="/about-package"
              element={<CropDetails/>}
          />
      </Routes>
        <ToastContainer />
    </div>
  );
}

export default App;
