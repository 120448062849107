import React from "react";
import Img from "../../assets/images/no-product-found.png";

export default function NoProductFound() {
  return (
    <div>
      <img src={Img} alt="" className="no-prod-img"></img>
      <p className="no-prod-txt1">Sorry, no product found !!</p>
      <p className="no-prod-txt2">Please Scan a valid QR Sticker</p>
    </div>
  );
}
